import {
  Paper, Typography, CircularProgress, Stack, Alert, useMediaQuery,
} from '@mui/material';
import React, {
  useEffect,
  useMemo,
  useState,
  useContext,
} from 'react';
import { Box, Container, styled } from '@mui/system';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import JsxParser from 'react-jsx-parser';
import { useSnackbar } from 'material-ui-snackbar-provider';
import { Form } from 'react-final-form';
import { useTheme } from '@emotion/react';
import { useConfirm } from 'material-ui-confirm';

import { getInviteFormGql, getInviteGql, updateInviteGql } from './gql';
import ParticipantPrompt from './components/ParticipantPrompt';
import MainParticipantPrompt from './components/MainParticipantPrompt';
import TableSeatingPlan from './components/TableSeatingPlan';
import AttendanceConfirmation from './components/AttendanceConfirmation';
import StepButtons from './components/StepButtons';
import Toc from './components/Toc';
import SwitchForm from './components/SwitchForm';
import { GeneralContext } from '../../util/Store';
import TableSizePrompt from './components/TableSizePrompt';
import NotFound from '../../Components/NotFound';
import RsvpHeading from '../../Components/RsvpHeading';
import { findMissingTableAssignments, mapSeatingIntoAttendees } from './helper';

const ContentContainer = styled(Container)({
  // padding: '10vh 42px 16px 42px',
});

const PaperBox = styled(Paper)((props) => ({
  padding: props.matchesSm ? '18px 18px 18px 18px' : '36px 36px 36px 36px',
  position: 'relative',
}));

export default function InviteResponse() {
  const snackbar = useSnackbar();
  const { inviteCode } = useParams();
  const [attendees, setAttendees] = useState([]);
  const [steps, setSteps] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();
  const generalStore = useContext(GeneralContext);
  const theme = useTheme();
  const confirm = useConfirm();
  const [searchParams] = useSearchParams();
  const matchesSm = useMediaQuery(theme.breakpoints.down('sm'));

  const {
    data,
    loading,
  } = useQuery(getInviteGql, {
    fetchPolicy: 'no-cache',
    variables: { inviteCode },
    onCompleted: ({ getInvite }) => {
      if (document.cookie.includes('isAdmin=true') && (searchParams.has('update') || getInvite.status === 'created')) {
        setSteps(getInvite.inviteForm.elements);
        return;
      }

      if (getInvite.status === 'accepted') {
        navigate(`/einladung/${inviteCode}/accepted`);
        return;
      }
      if (getInvite.status === 'declined') {
        navigate(`/einladung/${inviteCode}/declined`);
        return;
      }
      navigate(`/einladung/${inviteCode}/closed`);
      // setSteps(getInvite.inviteForm.elements);
    },
    onError: () => snackbar.showMessage('Fehler beim laden.'),
  });

  const [getInviteForm, { loading: inviteFormLoading }] = useLazyQuery(getInviteFormGql, { fetchPolicy: 'no-cache' });

  const invite = useMemo(() => data?.getInvite, [data?.getInvite]);
  useEffect(() => {
    if (!data?.getInvite) return;
    setAttendees(data?.getInvite.attendees);
    generalStore.setContactInfo(data?.getInvite.inviteForm.frontendSettings.contactInfo);
  }, [data?.getInvite]);

  const initialValues = useMemo(() => {
    if (!data?.getInvite) return {};
    const attendees = data?.getInvite.attendees;

    const seating = [];
    if (data?.getInvite.seat !== undefined && data?.getInvite.seat !== null && data?.getInvite.seatTableId !== undefined) {
      if (!seating[data.getInvite.seatTableId]) seating[data.getInvite.seatTableId] = [];
      seating[data.getInvite.seatTableId || 0][data.getInvite.seat - 1] = 'main';
    }
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const index in attendees) {
      const attendee = attendees[index];
      if (attendee.seat === undefined) continue;
      if (!seating[attendee.eventTableId || 0]) seating[attendee.eventTableId || 0] = [];
      seating[attendee.eventTableId || 0][attendee.seat - 1] = attendee.id || attendee.tempId;
    }

    const switchFormElement = data?.getInvite.inviteForm.elements.find(({ type }) => type === 'switchForm');
    let switchForm = null;
    if (switchFormElement && data?.getInvite.status === 'declined') {
      switchForm = 'inviteDecline:';
    }

    return {
      title: data?.getInvite.title,
      forename: data?.getInvite.forename,
      name: data?.getInvite.name,
      company: data?.getInvite.company,
      email: data?.getInvite.email,
      status: data?.getInvite.status,
      eventTable: data?.getInvite.eventTable ? { seatCount: data?.getInvite.eventTable?.seatCount?.toString() } : undefined,
      eventTables: data?.getInvite.eventTables?.length
        ? data?.getInvite.eventTables.map((table) => { table.seatCount = table.seatCount.toString(); return table; })
        : [{ seatCount: null }],
      customAttributes: (data?.getInvite.customAttributes || {}),
      attendees,
      seating,
      switchForm,
    };
  }, [data?.getInvite]);

  const [updateInvite, { loading: updateInviteLoading }] = useMutation(updateInviteGql, {
    onCompleted: () => {
      navigate(`/einladung/${inviteCode}/accepted`);
    },
    onError: (err) => {
      console.error(err);
      navigate(`/einladung/${inviteCode}/error`);
    },
  });

  if (loading) {
    return (
      <Stack alignItems="center" sx={{ mt: 5 }}>
        <CircularProgress sx={{ color: 'white' }} />
      </Stack>
    );
  }

  const renderFormElements = (element) => {
    const submitType = invite.status === 'created' ? 'create' : 'update';
    switch (element.type) {
      case 'participantPrompt':
        return (
          <ParticipantPrompt
            {...element}
            invite={invite}
            attendees={attendees}
            submitType={submitType}
          />
        );
      case 'mainParticipantPrompt':
        return (
          <MainParticipantPrompt
            {...element}
            steps={steps}
            invite={invite}
            attendees={attendees}
            submitType={submitType}
          />
        );
      case 'tableSeatingPlan':
        return (
          <TableSeatingPlan
            {...element}
            invite={invite}
            attendees={attendees}
            submitType={submitType}
          />
        );
      case 'attendanceConfirmation':
        return (
          <AttendanceConfirmation
            {...element}
            invite={invite}
            attendees={attendees}
            submitType={submitType}
          />
        );
      case 'tableSizePrompt':
        return (
          <TableSizePrompt
            {...element}
            invite={invite}
            attendees={attendees}
            submitType={submitType}
          />
        );
      case 'toc':
        return (
          <Toc
            {...element}
            invite={invite}
            attendees={attendees}
            submitType={submitType}
          />
        );
      case 'switchForm':
        return (
          <SwitchForm
            {...element}
            invite={invite}
            attendees={attendees}
            onStepChange={(newSteps) => setSteps(newSteps)}
            submitType={submitType}
          />
        );
      default:
        return (<h1>{element.type}</h1>);
    }
  };

  const mapAttendees = (attendee, data) => {
    let companionIndex;
    if (attendee.companionTempId) {
      companionIndex = data.attendees.findIndex(({ tempId }) => tempId === attendee.companionTempId);
    }
    return {
      id: attendee.id,
      title: attendee.title,
      forename: attendee.forename,
      name: attendee.name,
      email: attendee.email || data.email,
      customAttributes: attendee.customAttributes,
      seat: attendee.seat,
      seatTable: attendee.seatTable,
      waitingList: attendee.waitingList || false,
      companionIndex: companionIndex !== -1 ? companionIndex : undefined,
      ticketCategory: attendee?.ticketCategory?.id ? { id: attendee.ticketCategory.id } : undefined,
    };
  };

  const onSubmit = async (data, visibleSteps) => {
    let currentSteps = visibleSteps || steps;
    if (
      (invite.inviteForm.elements[activeStep]?.type === 'attendanceConfirmation' && data.status === 'declined')
      || (data.customAttributes && data.customAttributes.switchForm?.startsWith('inviteDecline'))
    ) {
      await updateInvite({
        variables: {
          inviteCode,
          input: { status: 'declined', attendees: data.attendees?.map((attendee) => mapAttendees(attendee, data)), customAttributes: data.customAttributes },
        },
      });
      navigate(`/einladung/${inviteCode}/declined`);
      return;
    }

    const currentStep = currentSteps[activeStep];

    if (currentStep?.params?.checkQuota
        && invite.quotas && !invite.quotas.find(({ main }) => main) && invite.status !== 'accepted'
        && !(invite.seatTableId || invite.eventTables?.length) // does not belong to a table
    ) {
      await confirm({
        title: 'Warteliste',
        description: `
          Leider sind bereits alle Plätze vergeben.
          Sie haben die Möglichkeit sich für die Warteliste zu registrieren.
        `,
        confirmationText: 'Für Warteliste registrieren',
        cancellationText: 'Nicht registrieren',
      });
      data.waitingList = true;
    }

    if (currentStep.type === 'switchForm') {
      if (data.waitingList) {
        await confirm({
          title: 'Warteliste',
          description: `
            Leider sind bereits alle Plätze vergeben.
            Sie haben die Möglichkeit sich für die Warteliste zu registrieren.
          `,
          confirmationText: 'Für Warteliste registrieren',
          cancellationText: 'Nicht registrieren',
        });
      }

      data.status = 'accepted';
      const result = await getInviteForm({
        variables: { formInviteId: data.customAttributes.switchForm.replace('formSwitch:', '') },
      });

      currentSteps = [...invite.inviteForm.elements, ...result.data.getInviteForm.elements];
      setSteps([...invite.inviteForm.elements, ...result.data.getInviteForm.elements]);
    }

    if (currentStep.type === 'mainParticipantPrompt' && currentStep.description && currentStep?.params?.showModal) {
      await confirm({
        title: '',
        hideCancelButton: true,
        allowClose: false,
        description: (
          <Alert severity="info" sx={{ mt: 3 }}>
            <Typography>
              <JsxParser
                components={{ Typography, Stack }}
                jsx={currentStep.description}
              />
            </Typography>
          </Alert>
        ),
        confirmationText: 'Weiter',
      });
    }

    if (currentStep.type === 'participantPrompt' && currentStep?.params?.participantCategoryGroupings && data.eventTables?.[0]?.seatCount) {
      const ticketCategoryForCompleteTable = currentStep.params.participantCategoryGroupings
        .filter(({ onlyCompleteTable }) => onlyCompleteTable)
        .map(({ ticketCategoryId, additionalCategoryIds }) => [ticketCategoryId, ...(additionalCategoryIds || [])])
        .flat();

      const tableHasToBeComplete = !!data.attendees?.find(({ ticketCategory }) => ticketCategoryForCompleteTable.includes(ticketCategory.id))
        && data.customAttributes?.allowOnlyCompleteTableRegistration !== 'false';

      const seatCount = parseInt(data.eventTables?.[0].seatCount, 10);
      if (tableHasToBeComplete && (data.attendees.length + 1) < seatCount) {
        await confirm({
          title: 'Fehlende Gäste oder Begleitpersonen',
          hideCancelButton: true,
          allowClose: false,
          description: (
            <Alert severity="error" sx={{ mt: 3 }}>
              <Typography>
                Um fortzufahren, ist es notwendig, dass Sie alle
                {' '}
                {data.eventTables[0].seatCount}
                {' '}
                Personen Ihres Tisches angeben.
                <br />
                <br />
                Es fehlen noch
                {' '}
                {(data.eventTables[0].seatCount - 1) - data.attendees.length}
                {' '}
                Gäste oder Begleitpersonen.
              </Typography>
            </Alert>
          ),
          confirmationText: 'Zurück',
        });
        return;
      }
    }

    if (currentStep.type === 'participantPrompt' && data.waitingList) {
      await confirm({
        title: '',
        hideCancelButton: true,
        allowClose: false,
        description: (
          <Alert severity="info" sx={{ mt: 3 }}>
            <Typography>
              Beim Abschließen der Registrierung werden Sie automatisch in unsere Warteliste aufgenommen.
              Bitte weisen Sie keine Zahlungen oder Spenden an, solange Sie keine Rückmeldung von uns erhalten haben.
            </Typography>
          </Alert>
        ),
        confirmationText: 'Weiter',
      });
    }

    if (currentStep.type === 'tableSeatingPlan') {
      const copiedData = { ...data };
      mapSeatingIntoAttendees(copiedData, steps);
      const missingAssignments = findMissingTableAssignments(copiedData);
      if (missingAssignments.length) {
        await confirm({
          title: 'Fehlende Sitzplatzzuweisung',
          hideCancelButton: true,
          allowClose: false,
          description: (
            <Alert severity="error" sx={{ mt: 3 }}>
              <Typography>
                Folgenden Personen wurde noch kein Sitzplatz zugewiesen:
              </Typography>
              <ul>
                {missingAssignments.map(({ forename, name }) => (
                  <li>
                    {forename}
                    {' '}
                    {name}
                  </li>
                ))}
              </ul>
              <Typography>
                Bitte kehren Sie zurück und nehmen die Zuweisung vor.
              </Typography>
            </Alert>
          ),
          confirmationText: 'Zurück',
        });
        return;
      }
    }

    if (activeStep !== (currentSteps.length - 1)) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setActiveStep((curr) => curr + 1);
      return;
    }

    mapSeatingIntoAttendees(data, steps);

    const mainParticipantPromptStep = steps.find(({ type }) => type === 'mainParticipantPrompt');
    const tableSizePromptStep = steps.find(({ type }) => type === 'tableSizePrompt');
    let mainParticipant = {};
    if (mainParticipantPromptStep) {
      mainParticipant = {
        title: data.title,
        forename: data.forename,
        name: data.name,
        company: data.company,
        email: data.email,
        seat: data.seat,
        seatTable: data.seatTable,
        customAttributes: data.customAttributes,
      };
    }

    const hasAttendanceConfirmation = currentSteps.find(({ type }) => type === 'attendanceConfirmation');

    await updateInvite({
      variables: {
        inviteCode,
        input: {
          ...mainParticipant,
          status: hasAttendanceConfirmation ? data.status : 'accepted',
          eventTable: tableSizePromptStep ? data.eventTable : undefined,
          eventTables: tableSizePromptStep ? data.eventTables : undefined,
          waitingList: data.waitingList || false,
          attendees: data.attendees?.map((attendee) => mapAttendees(attendee, data)),
        },
      },
    });
  };

  const visibleSteps = steps.filter((step) => step.isVisible !== false);

  return (
    <ContentContainer maxWidth="lg">
      <RsvpHeading />
      <PaperBox sx={{ pb: 2, mb: 2 }} elevation={4} matchesSm={matchesSm}>

        {!inviteCode || !invite ? (
          <NotFound />
        ) : (
          <Form
            onSubmit={(data) => onSubmit(data, visibleSteps)}
            initialValues={initialValues}
            render={({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit}>
                {visibleSteps.map((step, index) => {
                  if (activeStep !== index) return null;
                  return (
                    <>
                      <Typography variant="h5">
                        {/* {index + 1}
                        . */}
                        {' '}
                        {step.title}
                      </Typography>
                      {updateInviteLoading || inviteFormLoading ? (
                        <Box sx={{
                          display: 'flex', flexDirection: 'column', alignItems: 'center', m: 6,
                        }}
                        >
                          <CircularProgress size={80} />
                        </Box>
                      ) : (
                        <>
                          {step.description && (
                            <Alert severity="info" sx={{ mt: 3 }}>
                              <Typography>
                                <JsxParser
                                  components={{ Typography, Stack }}
                                  jsx={step.description}
                                  bindings={{ values }}
                                />
                              </Typography>
                            </Alert>
                          )}
                          {renderFormElements(step)}

                          <StepButtons
                            currentIndex={index}
                            onBackClick={() => { setActiveStep((curr) => curr - 1); window.scrollTo({ top: 0, behavior: 'smooth' }); }}
                            elements={visibleSteps}
                            currentElement={step}
                          />
                        </>
                      )}
                    </>
                  );
                })}
              </form>
            )}
          />
        )}
      </PaperBox>

    </ContentContainer>

  );
}
